
































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import {
  GET_ROLES,
  FETCH_ROLES,
  UPDATE_USER_ROLES,
} from '../store/roles';
import { Role } from '../store/roles/types';

interface Data {
  isLoading: boolean;
}

export default Vue.extend({
  name: "UserEditRoleDialog",
  props: ['show', 'user', 'selectedRoles'],
  data: (): Data => {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      roles: GET_ROLES,
    }),
    // mutate the dialog state (show or hide)
    selected: {
      get(): string[] {
        return this.selectedRoles;
      },
      set(value) {
        this.$emit('update:selectedRoles', value);
      },
    },
    isDisabled(): boolean {
      // const isEmpty: boolean = this.selected.length === 0;

      let userRoles: string[] = [];
      if ('roles' in this.user && !!this.user.roles.length)
        userRoles = this.user.roles.map((r: Role) => r.longName);
      const isEqual: boolean =
        (this.selected as string[]).length === userRoles.length &&
        (this.selected as string[]).every((r) => userRoles.includes(r));

      // return isEmpty || isEqual;
      return isEqual;
    },
  },
  methods: {
    async updateUserRoles(): Promise<void> {
      this.isLoading = true;
      try {
        await this.$store.dispatch(UPDATE_USER_ROLES, {
          roles: this.selected,
          user: this.user,
        });
      } catch (e) {
      } finally {
        this.isLoading = false;
        this.cancel();
      }

      // user id, map role name to role id
      // dispatch
    },
    cancel(): void {
      if (!this.isLoading) this.$emit('update:show', false);
    },
  },
  async beforeMount() {
    await this.$store.dispatch(FETCH_ROLES);
  },
});
