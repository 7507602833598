
















































































































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { ListUser, UPDATE_USER } from "@/store/users/index";
import { GET_ACDB, FETCH_SINGLE_ACDB } from "@/store/acdb/index";
import { GET_CURRENT_USER } from '@/store/users/index';
import i18n from '@/i18n';
import { isEqual, cloneDeep } from 'lodash-es';
import { Role } from '@/store/roles/types';

// Components
import UserChangePasswordDialog from '@/components/UserChangePasswordDialog.vue';
import UserEditRoleDialog from '@/components/UserEditRoleDialog.vue';

function getForbiddenChar(value: string) {
  if (value === '' || value === null || value === undefined) return false;

  const regex = new RegExp(/[^a-zA-ZÀ-ÿ0-9 ',.@\-+]/g);
  const occurences = value.match(regex) || [];

  if (occurences.length > 0) {
    // unique values
    const result = Array.from(new Set(occurences));
    // toString: joins values with a comma
    return result.join(', ');
  }
  return false;
}

function mandatoryRule(value: string) {
  return !!value || i18n.t('mandatoryField');
}

function forbiddenCharRule(value: string) {
  return (
    !getForbiddenChar(value) ||
    i18n.t('forbiddenCharacters', {
      forbiddenChar: `"${getForbiddenChar(value)}"`,
    })
  );
}

function emailRule(value: string) {
  if (value === null || value === undefined || value === '') return true;

  const emailRegex = new RegExp(/\S{2,}@\S{2,}\.[a-z]{2,3}$/g);
  const res = emailRegex.test(value);
  if (!res) return i18n.t('invalidEmail');

  const forbiddenRegex = new RegExp(/[^a-zA-Z0-9À-ÿ,_\-.@]/g);
  const occurences = value.match(forbiddenRegex) || [];

  if (occurences.length > 0) {
    const result = Array.from(new Set(occurences)).join(', ');
    return i18n.t('forbiddenCharacters', { forbiddenChar: `"${result}"` });
  }

  return true;
}

export default Vue.extend({
  name: 'UserEditCard',
  components: {
    UserChangePasswordDialog,
    UserEditRoleDialog,
  },
  props: {
    user: {
      type: Object as () => ListUser,
      required: false,
    },
    exitEditUserForm: {
      type: Function,
      required: false,
    },
    attributesToModify: {
      type: Function,
      required: false,
    },
    showRoles: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      canSave: false,
      formValid: false,
      modifiedUser: {} as ListUser,
      showPasswordDialog: false,
      showEditUserRoles: false,
      selectedRoles: [] as string[],
      fields: [
        {
          index: 1,
          name: 'login',
          rules: [mandatoryRule, forbiddenCharRule],
          error: '',
        },
        {
          index: 2,
          name: 'firstName',
          rules: [mandatoryRule, forbiddenCharRule],
          error: '',
        },
        {
          index: 3,
          name: 'name',
          rules: [mandatoryRule, forbiddenCharRule],
          error: '',
        },
        {
          index: 4,
          name: 'email',
          rules: [emailRule],
          error: '',
        },
      ],
    };
  },
  beforeMount() {
    // Make sure the right to update the users are fetched and in the store
    if (this.getAcdb('update', 'user') === undefined)
      this.fetchSingleAcdb('update', 'user');
    if (this.getAcdb('update', 'ownUser') === undefined)
      this.fetchSingleAcdb('update', 'ownUser');
    this.modifiedUser = Object.assign({}, this.user);
  },
  mounted() {
    (this.$refs.editUserForm as any).validate();
  },
  methods: {
    ...mapActions({
      fetchSingleAcdb: FETCH_SINGLE_ACDB,
      updateUser: UPDATE_USER,
    }),
    canEditUser(user: ListUser) {
      // Has the user the permission to update any user ?
      // Or its own user ?
      return (
        this.getAcdb('update', 'user').hasAccess === true ||
        (this.getAcdb('update', 'ownUser').hasAccess === true &&
          this.currentUser.id === user.id)
      );
    },
    canEditAttribute(attributeName: string) {
      // If the current user wants to update it's own information
      return this.attributesToModify().includes(attributeName);
    },
    canReadUserRoles() {
      return this.getAcdb('read', 'roleAssignment').hasAccess;
    },
    async saveModifications() {
      let index = this.fields.findIndex(f => f.name === 'login');
      try {
        await this.updateUser(this.modifiedUser);
        this.fields[index].error = '';
        this.exitEditUserForm();
      } catch (e) {
        const error = e as any;
        this.fields[index].error = error;
      }
    },
    cancelUserEdit() {
      let index = this.fields.findIndex(f => f.name === 'login');
      this.fields[index].error = '';
      this.exitEditUserForm();
    },
    onChangeRoles(user: ListUser) {
      if ('roles' in user && user.roles !== undefined)
        this.selectedRoles = user.roles.map((r: Role) => r.longName);
      else this.selectedRoles = [];

      this.showEditUserRoles = true;
    },
  },
  computed: {
    ...mapGetters({
      getAcdb: GET_ACDB,
      currentUser: GET_CURRENT_USER,
    }),
    hasChanged(): boolean {
      return !isEqual(this.user, this.modifiedUser);
    },
    permission(): string | null {
      let ownUserAcdb = this.getAcdb('update', 'ownUser');
      let isSameUser = this.user.id === this.currentUser.id;
      if (ownUserAcdb.hasAccess && isSameUser) return 'ownUser';

      let anyUserAcdb = this.getAcdb('update', 'user');
      if (anyUserAcdb.hasAccess) return 'user';

      return null;
    },
  },
  watch: {
    user: {
      deep: true,
      handler(newUser: ListUser, oldUser) {
        this.modifiedUser = cloneDeep(newUser);
      },
    },
    modifiedUser: {
      deep: true,
      handler(_newModifiedUser, _oldModifiedUser) {
        if (!this.modifiedUser.blocked) {
          this.modifiedUser.blockingReason = '';
        }

        this.canSave = this.formValid && this.hasChanged;
      },
    },
    formValid: function(_value, _oldValue) {
      this.canSave = this.formValid && this.hasChanged;
    },
  },
});
