




































































































































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { mdiContentCopy, mdiEye, mdiEyeOff } from '@mdi/js';

import { FETCH_SINGLE_ACDB, GET_ACDB } from '../store/acdb';
import { ADD_NOTIFICATION } from '../store/notifications';
import {
  UPDATE_USER,
  GET_CURRENT_USER,
  UPDATE_USER_PASSWORD,
} from '../store/users';

import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

function mandatoryRule(value: string) {
  return !!value || i18n.t('mandatoryField');
}

interface Data {
  // icons
  mdiContentCopy: string;
  mdiEye: string;
  mdiEyeOff: string;
  // common data
  isLoading: boolean;
  isSuccess: boolean;
  isValid: boolean;
  // copy to clipboard
  clipboardText: string | TranslateResult;
  clipboardTimeout: null | number | any;
  copiedToClipboard: string | TranslateResult | null;
  // reset password
  newPassword: string | null;
  successTitle: string | TranslateResult;
  // change own password
  oldPasswordModel: string;
  oldPasswordToggle: boolean;
  passwordModel: string;
  passwordToggle: boolean;
  confirmPasswordModel: string;
  confirmPasswordToggle: boolean;
  confirmPasswordError: string | TranslateResult | null;
  textFieldRules: Function[];
}

export default Vue.extend({
  name: "UserChangePasswordDialog",
  props: ['user', 'show'],
  data(): Data {
    return {
      // icons
      mdiEye,
      mdiEyeOff,
      mdiContentCopy,
      // common data
      isLoading: false,
      isSuccess: false,
      isValid: false,
      // copy to clipboard
      clipboardText: this.$i18n.t('clipboardCopied'),
      clipboardTimeout: null,
      copiedToClipboard: '',
      // reset password
      newPassword: '',
      successTitle: i18n.t('users.resetPassword.successfullyReset'),
      // change own password
      oldPasswordModel: '',
      oldPasswordToggle: false,
      passwordModel: '',
      passwordToggle: false,
      confirmPasswordModel: '',
      confirmPasswordToggle: false,
      confirmPasswordError: '',
      textFieldRules: [mandatoryRule],
    };
  },
  beforeMount() {
    // Make sure the right to update the users are fetched and in the store
    if (this.getAcdb('update', 'user') === undefined)
      this.fetchSingleAcdb('update', 'user');
    if (this.getAcdb('update', 'ownUser') === undefined)
      this.fetchSingleAcdb('update', 'ownUser');
  },
  methods: {
    ...mapActions({
      fetchSingleAcdb: FETCH_SINGLE_ACDB,
      updateUser: UPDATE_USER,
    }),
    /**
     * Reset the user password identified by userId.
     * @returns {void|Error} An error or an empty response.
     */
    async reset(): Promise<void> {
      try {
        this.isLoading = true;

        /** The HTTP request is triggered from a vuex action
         *
         * @param userId The user id
         * @param newPassword The new password
         * @param isOwnUser (optional) If the currentUser = selected User
         */
        await this.$store.dispatch(UPDATE_USER_PASSWORD, {
          userId: this.user.id,
          newPassword: this.newPassword,
          isOwnUser: false,
        });

        this.isSuccess = true;
        this.isLoading = false;
        this.$store.commit(ADD_NOTIFICATION, {
          message: this.successTitle,
          color: 'success',
        });
      } catch (error) {
        this.$store.commit(ADD_NOTIFICATION, {
          message: error,
          color: 'error',
        });
        // To stop the modal from loading in any case.
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Update the user own password.
     * @returns {void|Error} An error or an empty response.
     */
    async change(): Promise<void> {
      try {
        this.isLoading = true;

        await this.$store.dispatch(UPDATE_USER_PASSWORD, {
          currentPassword: this.oldPasswordModel,
          newPassword: this.passwordModel,
          isOwnUser: true,
        });

        this.$store.commit(ADD_NOTIFICATION, {
          message: 'passwordSuccessfullyChanged',
          color: 'success',
        });
        this.isLoading = false;
        this.cancel(null);
      } catch (error) {
        console.log('error', error);
        this.$store.commit(ADD_NOTIFICATION, {
          message: error,
          color: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * The cancel method is responsible of closing the modal and set the
     * variables in their initial state, the action can be triggered by:
     *
     *  - clicking a button,
     *  - clicking outside the dialog,
     *  - releasing the ESC key
     *
     * The cancel method can be executed only if the page isn't loading.
     */
    cancel(value: any): void {
      if (this.permission === 'user' && !this.isSuccess)
        (this.$refs.resetPasswordForm as any).reset();
      if (this.permission === 'ownUser' && !this.isSuccess)
        (this.$refs.changePasswordForm as any).reset();
      if (!this.isLoading) {
        this.$emit('update:show');
        // reset password
        if (this.permission === 'user' && !this.isSuccess)
          (this.$refs.resetPasswordForm as any).reset();

        this.isSuccess = this.isLoading = false;
        this.copiedToClipboard = '';
        this.newPassword = '';

        // change own password
        if (this.permission === 'ownUser' && !this.isSuccess)
          (this.$refs.changePasswordForm as any).reset();

        this.confirmPasswordModel = '';
        this.confirmPasswordError = '';

        this.oldPasswordToggle = this.passwordToggle = this.confirmPasswordToggle = false;
      }
    },
    /**
     * Copy text in area (input or textarea) in the clipboard.
     *
     * Display a hint for 5sec to inform that the text is correctly copied.
     *
     * TODO: create a generic version of this function and export it in the "utils"
     */
    textToCopy(): void {
      if (this.clipboardTimeout !== null) clearTimeout(this.clipboardTimeout);
      const textarea = this.$refs.textToCopy as any;
      const text = textarea.$el.querySelector('textarea');
      text.focus();
      text.select();
      document.execCommand('copy');
      (document.getSelection() as Selection).removeAllRanges();
      this.copiedToClipboard = this.clipboardText;
      const self = this;

      this.clipboardTimeout = setTimeout(
        () => (self.copiedToClipboard = ''),
        5000,
      );
    },
    /**
     * Check if the confirm password equal the password,
     * - Equal: return null
     * - Not equal: return an error message
     *
     * @param {string} value The data payload of the Event input
     */
    onInputConfirmPassword(value: string): void {
      this.confirmPasswordError = '';

      // one of the fields is not filled, nothing to do
      if (this.confirmPasswordModel === '' || this.passwordModel === '') return;

      this.confirmPasswordError =
        this.confirmPasswordModel === this.passwordModel
          ? null
          : i18n.t('users.changePassword.confirmPasswordError');
    },
    onCancelFocus(value: string): void {
      if (this.permission === 'user' && !this.isSuccess)
        (this.$refs.resetPasswordForm as any).reset();
    },
  },
  computed: {
    ...mapGetters({
      getAcdb: GET_ACDB,
      currentUser: GET_CURRENT_USER,
    }),
    successText() {
      const localePath = 'users.resetPassword.successTextarea';
      const { firstName, name, email, login } = this.user;
      const newPassword = this.newPassword;

      // add "as string otherwise Vetur extension and Vue trigger an error"
      let text = i18n.t(`${localePath}.user`, {
        firstName,
        name,
        login,
      }) as string;
      text += '\n' + i18n.t(`${localePath}.email`, { email });
      text += '\n\n' + i18n.t(`${localePath}.resetText`);
      text += '\n\n' + i18n.t(`${localePath}.newPassword`, { newPassword });
      text += '\n\n' + i18n.t(`${localePath}.resetNote`);

      return text;
    },
    /** Determine which form to display, a form for editing the own user or any use. */
    permission(): string | null {
      let ownUserAcdb = this.getAcdb('update', 'ownUser');
      let isSameUser = this.user.id === this.currentUser.id;
      if (ownUserAcdb.hasAccess && isSameUser) return 'ownUser';

      let anyUserAcdb = this.getAcdb('update', 'user');
      if (anyUserAcdb.hasAccess) return 'user';

      return null;
    },
    oldPasswordLabel() {
      return i18n.t('users.changePassword.oldPassword');
    },
    passwordLabel() {
      return i18n.t('users.newPassword');
    },
    confirmPasswordLabel() {
      return i18n.t('users.changePassword.confirmPassword');
    },
  },
});
